import dayjs, { Dayjs } from 'dayjs';

import type { LocalDate } from '@/common/graphql/scalars';
import { Maybe, type Nullable } from '@/common/types';
import { DayOfWeek } from '@/graphql/types';
import { isNullish } from '@/utils/common/isNullish';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

type DateFormatTypes = 'YYYY/MM/DD HH:mm' | 'M/D' | 'HH:mm';

export const formatDateToYYYYMD = (date: Maybe<Date | null | string>): string => {
  return date ? dayjs(date).format('YYYY/M/D') : '';
};

export const formatDateToYYYYMMDDForInput = (date: Maybe<string | Date | null>): string => {
  return date ? dayjs(date).format('YYYY-MM-DD') : '';
};

export const formatDateToLocalDate = (date: string | Date): LocalDate => {
  return dayjs(date).format('YYYY-MM-DD') as LocalDate;
};

export const parseNullableLocalDate = (date: Nullable<string | Date>): LocalDate | null => {
  return date ? (dayjs(date).format('YYYY-MM-DD') as LocalDate) : null;
};

export const parseNullableDate = (date: Nullable<string | Date>): Date | null => {
  return date ? dayjs(date).toDate() : null;
};

export const formatDateToYYYYMDHHmm = (date: Maybe<Date | null | string>): string => {
  return date ? dayjs(date).format('YYYY/M/D HH:mm') : '';
};

export const formatDateToYYYYMMDDHHmmForInput = (date: Maybe<string | Date | null>): string => {
  return date ? dayjs(date).format('YYYY-MM-DD HH:mm') : '';
};

export const formatDateToYYYYMMDDHHmmForHtmlInput = (date: Maybe<string | Date | null>): string => {
  return date ? dayjs(date).format('YYYY-MM-DDTHH:mm') : '';
};

export const formatDateToYYYYMMForInput = (date: Maybe<Date | string | null>): string => {
  return date ? dayjs(date).format('YYYY-MM') : '';
};

export const formatDateToYYYYM = (date: Maybe<Date | string | null>): string => {
  return date ? dayjs(date).format('YYYY/M') : '';
};

export const formatDateToMD = (date: Maybe<Date | null>): string => {
  return date ? dayjs(date).format('M/D') : '';
};

export const formatDateToMDHHmm = (date: Maybe<Date | null>): string => {
  return date ? dayjs(date).format('M/D HH:mm') : '';
};

export const formatDateToHHmm = (date: Maybe<Date | null>): string => {
  return date ? dayjs(date).format('HH:mm') : '';
};

export const formatDateToMD_or_YYYYMD = (date: Maybe<Date | null>): string => {
  if (!date) {
    return '';
  }

  const currentYear = new Date().getFullYear();
  const inputYear = new Date(date).getFullYear();

  return currentYear === inputYear ? dayjs(date).format('M/D') : dayjs(date).format('YYYY/M/D');
};

export const formatDateToMDHHmm_or_YYYYMDHHmm = (date: Maybe<Date | null | string>): string => {
  if (!date) {
    return '';
  }

  const currentYear = new Date().getFullYear();
  const inputYear = new Date(date).getFullYear();

  return currentYear === inputYear
    ? dayjs(date).format('M/D HH:mm')
    : dayjs(date).format('YYYY/M/D HH:mm');
};

export const formatDateToHHmm_or_MD_or_YYYYMD = (date: Maybe<Date | null>): string => {
  if (!date) {
    return '';
  }

  const now = dayjs();
  const inputDate = dayjs(date);

  if (now.isSame(inputDate, 'day')) {
    return inputDate.format('HH:mm');
  } else if (now.isSame(inputDate, 'year')) {
    return inputDate.format('M/D');
  } else {
    return inputDate.format('YYYY/M/D');
  }
};

export const getTodayAndSixDaysAgoDate = (date?: Date): [Date, Date] => {
  const today = dayjs(date);
  const lastWeeksDay = today.add(-6, 'day');
  return [today.toDate(), lastWeeksDay.toDate()];
};

export const getTodayAndAfterSixDaysDate = (date?: Date): [Date, Date] => {
  const today = dayjs(date);
  const lastWeeksDay = today.add(6, 'day');
  return [today.toDate(), lastWeeksDay.toDate()];
};

export const getFirstDayAndLastDayOfMonth = (date?: Date): [Date, Date] => {
  const oneDay = dayjs(date);
  return [oneDay.startOf('month').toDate(), oneDay.endOf('month').toDate()];
};

export const getPrevNextDateFromFirstDayAndLastDayOfMonth = (date?: Date): [Date, Date] => {
  const [firstDay, lastDay] = getFirstDayAndLastDayOfMonth(date);
  return [getTodayAndSixDaysAgoDate(firstDay)[1], getTodayAndAfterSixDaysDate(lastDay)[1]];
};

export const getDifferenceInMinutes = (from?: Date, to?: Date): string => {
  return Math.abs(dayjs(to).diff(from, 'minute')).toString();
};

export const getTodayDateYYYYMMDD = (): string => {
  return dayjs().format('YYYYMMDD');
};

export const isFromDateAfterToDate = (
  from: string | Date | null,
  to: string | Date | null
): boolean => {
  if (!from || !to) return false;
  return dayjs(from).isAfter(dayjs(to));
};

// fromがtoよりも後か同じ日付かどうか
export const isFromDateAfterSameToDate = (from?: string | Date, to?: string | Date): boolean => {
  if (!from || !to) return false;
  return dayjs(from).isAfter(dayjs(to)) || dayjs(from).isSame(dayjs(to));
};

export const isDateAfterToday = (from: string | Date): boolean => {
  return dayjs(from).isAfter(dayjs(), 'day');
};

export const isBeforeToday = (from: string | Date): boolean => {
  return dayjs(from).isBefore(dayjs(), 'day');
};

// 今日からdays日後以内かどうか
export const isBeforeDaysFromToday = (from: string | Date, days: number): boolean => {
  return dayjs(from).isBefore(dayjs().add(days, 'day'), 'day');
};

export const formatDate = (date: Maybe<Date | null>, format: DateFormatTypes): string => {
  if (format.length === 0 || !date) return '';
  return dayjs(date).format(format);
};

export const isToday = (date: Maybe<Date | null>): boolean => {
  if (!date) return false;
  return dayjs(date).isSame(dayjs(), 'day');
};

// 何日か前の日付を取得
export const getDaysAgo = (days: number): Date => {
  return dayjs().subtract(days, 'day').toDate();
};

export const getSameDayOfMonth = (
  fromDate: string,
  toDate: string,
  daysOfMonth: number[]
): string[] => {
  const startDate = dayjs(fromDate);
  const endDate = dayjs(toDate);

  const dates: Date[] = [];
  let currentDate = startDate;

  while (currentDate.isSameOrBefore(endDate)) {
    for (const day of daysOfMonth) {
      const currentDateDay = currentDate.date();
      if (currentDateDay === day) {
        dates.push(new Date(currentDate.format('YYYY-MM-DD')));
      } else if (currentDateDay === currentDate.endOf('month').date() && currentDateDay < day) {
        // 月末で存在しない場合は、月末の日付を追加
        dates.push(new Date(currentDate.format('YYYY-MM-DD')));
      }
    }
    currentDate = currentDate.add(1, 'day');
  }

  return dates.map((date) => formatDateToYYYYMMDDForInput(date));
};

export const getDatesFromNthDayOfWeekOfMonth = (
  fromDate: string,
  toDate: string,
  dayOfWeek: DayOfWeek,
  nth: number
): string[] => {
  const startDate = dayjs(fromDate);
  const endDate = dayjs(toDate);

  const dates: Date[] = [];
  let currentDate = startDate;

  while (currentDate.isSameOrBefore(endDate)) {
    if (
      currentDate.day() === convertDayOfWeekToNum(dayOfWeek) &&
      getNthDayOfWeek(currentDate) === nth
    ) {
      dates.push(new Date(currentDate.format('YYYY-MM-DD')));
    }
    currentDate = currentDate.add(1, 'day');
  }

  return dates.map((date) => formatDateToYYYYMMDDForInput(date));
};

export const isValidDate = (date: Date | string): boolean => {
  return dayjs(date).isValid();
};

export const toGraphqlValue = (date: Date | string | undefined | null): string | undefined => {
  if (isNullish(date)) return undefined;
  return typeof date === 'string' ? date : date.toISOString();
};

export const convertNumToDayOfWeek = (num: number): DayOfWeek => {
  switch (num) {
    case 0:
      return DayOfWeek.Sunday;
    case 1:
      return DayOfWeek.Monday;
    case 2:
      return DayOfWeek.Tuesday;
    case 3:
      return DayOfWeek.Wednesday;
    case 4:
      return DayOfWeek.Thursday;
    case 5:
      return DayOfWeek.Friday;
    case 6:
      return DayOfWeek.Saturday;
    default:
      return DayOfWeek.Sunday;
  }
};

export const convertDayOfWeekToNum = (dow: DayOfWeek): number => {
  switch (dow) {
    case DayOfWeek.Sunday:
      return 0;
    case DayOfWeek.Monday:
      return 1;
    case DayOfWeek.Tuesday:
      return 2;
    case DayOfWeek.Wednesday:
      return 3;
    case DayOfWeek.Thursday:
      return 4;
    case DayOfWeek.Friday:
      return 5;
    case DayOfWeek.Saturday:
      return 6;
  }
};

// 日付が、指定した曜日の何回目かを返す
export const getNthDayOfWeek = (date: Dayjs): number => {
  const nth = Math.floor((date.date() - 1) / 7) + 1;

  return nth;
};

export const createDateArray = (startDate: string, endDate: string): Dayjs[] => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const dates: Dayjs[] = [];

  let current = start;
  while (current.isSameOrBefore(end, 'day')) {
    dates.push(current);
    current = current.add(1, 'day');
  }

  return dates;
};
